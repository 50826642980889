<template>
  <div class="college-info">
    <base-box
      :topBarIcon="collegeInfoIcon"
      topBarLabel="大学信息"
      boxWidth="95%"
      boxHeight="520px"
      style="margin-top: 20px;"
    >
      <div slot="content" class="content">
        <div class="big">
          <div class="main-content">
            <div class="search-college">
              <el-input
                class="input"
                placeholder="输入学校名称"
                v-model="search"
                clearable
                @clear="getSchoolInfo"
              >
              </el-input>
            </div>
            <div class="table" style="width: 100%; min-height:300px;">
              <el-table :data="tables" stripe>
                 <el-table-column
                  label="院校名称"
                  align="center"
                   width="248"
                >
                 <template slot-scope="scope">
                    <a :href="scope.row.introduceUrl" target="_blank">{{scope.row.name}}</a>
                  </template>
                </el-table-column>
                <el-table-column
                  label="地区"
                  prop="area"
                  align="center"
                  width="100"
                />
                <!-- <el-table-column
                  label="院校代号"
                  align="center"
                  prop="mark"
                  width="100"
                /> -->
                <el-table-column
                  label="院校代码"
                  align="center"
                  prop="code"
                  width="100"
                />
                <el-table-column
                  label="邮编"
                  align="center"
                  prop="email"
                  width="100"
                >
                </el-table-column>
                <el-table-column label="咨询电话" prop="phone" align="center" width="152">
                </el-table-column>
                 <el-table-column label="院校地址" prop="address" align="center">
                </el-table-column>
                <el-table-column
                  label="院校网址"
                  align="center"
                >
                 <template slot-scope="scope">
                    <a :href="scope.row.introduceUrl" target="_blank">{{scope.row.introduceUrl}}</a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="margin-top:30px;">
              <el-pagination
                @size-change="handleSizeChange"
                :current-page="pageNo"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                :page-size="pageSize"
                layout="prev, pager, next, total, sizes, jumper"
                :total="Total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </base-box>
  </div>
</template>

<script>
import * as ApiMajorCollege from "@/api/majorCollegeInfo";
import BaseBox from "@/components/BaseBox/index.vue";

const globalRecordIcon = require("@/assets/images/icon_record@3x.png");
// 后面把图标换一下
export default {
  name: "CollegeInfo",
  components: {
    BaseBox,
  },
  data() {
    return {
        tables: [],
      // 跳转大学链接地址
      introduceUrl: "",
      // 学校名称
      search: "",
      // 分页
      //  pagination: {
      //   pageNo: '',  //当前页
      //   pageSize: '',
      //   total: '',
      // },
      pageNo: 1, // 当前页
      pageSize: 10, // 每页大小
      Total: 400, // 总页数
      getSearchAll: [], //总数据
      schoolTable: [
        // {
        //   address: "北京市海淀区双清路30号",
        //   introduceUrl: "http://www.tsinghua.edu.cn",
        //   name: "北京清华大学"
        // },
      ],
      collegeInfoIcon: globalRecordIcon, // 大学信息图标
    };
  },
//   computed: {
//     tables() {
//       const { search } = this;
//       if (search) {
//         return this.getSearchAll.filter((dataNews) =>
//           Object.keys(dataNews).some(
//             (key) =>
//               String(dataNews[key])
//                 .toLowerCase()
//                 .indexOf(search) > -1
//           )
//         );
//         console.log(this.getSearchAll);
//       }
//       return this.schoolTable;
//     },
//   },
  watch: {
    search: function() {
      if (this.search.length == 0) {
          this.pageNo = 0
    //   }else{
        //     this.getAllData();
      }
        this.getSchoolInfo();
    }
  },
  mounted() {
    this.getSchoolInfo();
    // this.getAllData();
  },
  methods: {
    // 根据名字搜索
    searchByNames() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          console.log(13131313);
          //  这里也可以判断一下是否有search
          // this.getSchoolInfo();
          // this.getAllData();
        }
      };
    },
    // 一个分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSchoolInfo();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getSchoolInfo();
    },

    // 获取学校信息
    getSchoolInfo() {
      const params = {
        nameSearch: this.search,
        pageNum: this.pageNo,
        pageSize: this.pageSize,
      };
      ApiMajorCollege.universityInfo(params).then((res) => {
        if (res.code == 0) {
          this.schoolTable = res.data.records; // 大学信息数据
          this.tables = res.data.records; // 大学信息数据
          console.log("学校数据", this.schoolTable);
          // this.schoolTable = res.data;
          // console.log(res.data.records);
          this.pageSize = Number(res.data.size);
          this.Total = Number(res.data.total);
        }
      });
    },

    // 获取所有数据
    getAllData() {
      const params = {
        nameSearch: this.search,
      };
      ApiMajorCollege.universityInfoAll(params).then((res) => {
        if (res.code == 0) {
          // this.schoolTable = res.data.records; // 大学信息数据
          // console.log("学校数据",this.schoolTable)
          console.log("所有", res.data);
          this.getSearchAll = res.data; //函数过后表格数据改变了
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// .el-table {
//   font-size: 17px;
// }

.big {
  width: 100%;
  display: flex;
  /*!*flex-direction: column;*!可写可不写*/
  justify-content: center;
  align-items: center;
}
/deep/ .el-table__body-wrapper {
  height: 300px;
  overflow-y: auto;
}
.main-content {
  width: 100%;
}
.search-college {
  height: 70px;
  width: 25%;
}
.collegeTable {
}

/* // a标签 */
a {
  text-decoration: none;
  color: #409eff;
}
a:hover,
a:visited,
a:link,
a:active {
  color: #409eff;
  /* // 设置使所有a标签的四种状态都和本身颜色保持一致 */
}
</style>
